import { Button } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import Modal from "@material-ui/core/Modal"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import { type Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import React from "react"
import { getIepList } from "../api"
import UpdateUploadFileMap from "../components/UpdateUploadFileMap"
import Layout from "../components/layout/Layout"

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			margin: theme.spacing(3),
		},
		wrapper: {
			width: "100%",
			height: "100%",
		},
		inputWrapper: {
			position: "absolute",
			overflow: "hidden",
			display: "inline",
			width: "100%",
			height: "50%",
			zIndex: 1,
			opacity: 0,
			left: 0,
			top: 0,
		},
		paper: {
			position: "absolute",
			width: 500,
			backgroundColor: theme.palette.background.paper,
			border: "2px solid #000",
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
	}),
)

function getModalStyle() {
	const top = 50
	const left = 50
	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
	}
}

export default function () {
	const classes = useStyles()
	const [modalStyle] = React.useState(getModalStyle)
	const [iepId, setIepId] = React.useState<number | undefined>()
	const [iepList, setIepList] = React.useState<any | undefined>()
	const [open, setOpen] = React.useState(false)


	if (!iepList) {
		getIepList(setIepList)
	}

	const handleIepChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const id = parseInt(event.target.value?.toString())
		if (isNaN(id)) {
			return
		}

		setIepId(id)
	}

	const handleProceedClick = (_event: any, iepId: number) => {
		if (iepId) {
			setIepId(iepId)
			setOpen(true)
		}
	}


	return (
		<Layout>
			<Paper style={{ margin: 5 }}>
				<Modal
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open={open}>
					<div style={modalStyle} className={classes.paper}>
						<UpdateUploadFileMap iepId={iepId} handleClose={() => { setOpen(false) }} />
					</div>
				</Modal>
				<Box style={{ padding: 30 }}>
					<Grid
						container
						style={{
							width: "auto",
							margin: "0% 20%",
						}}>
						<Grid item sm={12} xs={12} style={{ padding: 20 }}>
							<Box justifyContent="center" display="flex">
								<h3>Manage Upload File Mappings</h3>
							</Box>
						</Grid>
						<Grid item sm={12}>
							<Grid container style={{ padding: 30 }}>
								<Grid item sm={12} style={{ margin: 10 }}>
									<TextField
										select
										fullWidth
										variant="outlined"
										label="Select IEP to map upload file columns."
										value={iepId}
										SelectProps={{ displayEmpty: true }}
										InputLabelProps={{ shrink: true }}
										onChange={handleIepChange}>

										{iepList?.map((item, index) => {
											if (iepList.length <= 0) {
												return <div />
											}
											return (
												<MenuItem key={item.id_} value={item.id_}>
													{item.abbrv}
												</MenuItem>
											)
										})}
										{/* Set a default value in case nothing loads */}
										{iepList?.length <= 0 && <MenuItem value={-1}>No IEPs loaded</MenuItem>}
									</TextField>
								</Grid>
							</Grid>
						</Grid>

						<Grid item sm={12}>
							<Box justifyContent="center" display="flex" style={{ margin: 30 }}>
								<Button
									variant="contained"
									color="primary"
									size="medium"
									disabled={iepId === undefined}
									onClick={(event) => handleProceedClick(event, iepId)}>
									Proceed
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</Layout>
	)
}
