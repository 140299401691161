import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import Modal from "@material-ui/core/Modal"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import DeleteIcon from "@material-ui/icons/Delete"
import React from "react"
import { getAPHistoryDetail } from "../../../api"
import { formatCurrency, formatDateNoTimezoneMMDDYYYY } from "../../../lib/formatters"
import { type Order, getComparator, stableSort } from "../../../lib/sort"
import TableToExcel from "../../TableToExcel/TableToExcel"
import WaitPopup from "../../WaitPopup"
import APHistoryTableDetails from "../apHistoryDetailsTable/APHistoryTableDetails"
import { useStyles } from "./styles"
import { type DataApSums, type HeadCell, HeaderDataApSums } from "./types"

interface EnhancedTableProps {
	classes: ReturnType<typeof useStyles>
	numSelected: number
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof DataApSums) => void
	order: Order
	orderBy: string
	rowCount: number
	headCells: HeadCell[]
}

function SortableTableHeaders(props: EnhancedTableProps) {
	const { classes, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props
	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => {
					return (
						<TableCell
							key={headCell.id}
							align={headCell.numeric ? "right" : "left"}
							sortDirection={orderBy === headCell.id ? order : false}
							padding={"checkbox"}
							style={{
								backgroundColor: "rgba(4, 101, 170, 1)",
								color: "white",
							}}>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								hideSortIcon={true}>
								{headCell.label}
								{orderBy === headCell.id ? (
									<span className={classes.visuallyHidden}>
										{order === "desc" ? "sorted descending" : "sorted ascending"}
									</span>
								) : null}
							</TableSortLabel>
						</TableCell>
					)
				})}
				<TableCell
					padding={"checkbox"}
					style={{
						backgroundColor: "rgba(4, 101, 170, 1)",
						color: "white",
					}}>
					...
				</TableCell>
			</TableRow>
		</TableHead>
	)
}

function rand() {
	return Math.round(Math.random() * 10) - 10
}

function getModalStyle() {
	const top = 50 + rand()
	const left = 50 + rand()

	return {
		top: `${10}%`,
		left: `${30}%`,
		overflow: "auto",
	}
}

export default function (props: any) {
	const classes = useStyles()
	const [order, setOrder] = React.useState<Order>("asc")
	const [orderBy, setOrderBy] = React.useState<string>("name")
	const [selectedItem, setSelected] = React.useState<number>()
	const [apHistoryDetails, setApHistoryDetails] = React.useState<any | undefined>(undefined)
	const [deleteID, setDeleteID] = React.useState<number | undefined>()
	const [openDelete, setOpenDelete] = React.useState(false)
	const [openModal, setOpenModal] = React.useState(false)
	const [modalStyle] = React.useState(getModalStyle)
	const [openWait, setOpenWait] = React.useState<any | undefined>(false)

	React.useEffect(() => {
		setOpenWait(false)
		if (apHistoryDetails) {
			setOpenModal(true)
		}
	}, apHistoryDetails)

	const handleClose = () => {
		setOpenModal(false)
	}

	const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof DataApSums) => {
		const isAsc = orderBy === property && order === "asc"
		setOrder(isAsc ? "desc" : "asc")
		setOrderBy(property)
	}

	const handleClick = (event, id) => {
		getAPHistoryDetail(id, (data) => {
			if (data) {
				setApHistoryDetails(data)
			}
		})
		setOpenWait(true)
	}

	const handleDeleteClose = () => {
		setOpenDelete(false)
	}

	const handleDeleteClick = (id: number) => {
		setOpenDelete(true)
		setDeleteID(id)
	}

	const handleDeleteYes = () => {
		props.handleDeleteClick(deleteID)
		setOpenDelete(false)
	}

	return (
		<div style={{ height: "100%", width: "100%" }}>
			<WaitPopup open={openWait}>{"...Loading Info"} </WaitPopup>
			<Modal
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={openModal}
				onClose={handleClose}>
				<div style={modalStyle} className={classes.modal}>
					<APHistoryTableDetails invoiceData={apHistoryDetails} />
				</div>
			</Modal>
			<Modal
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={openDelete}>
				<Dialog
					open={openDelete}
					onClose={handleDeleteClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">
						{"Are you sure you want to Delete this item?"}
					</DialogTitle>
					<DialogActions>
						<Button onClick={handleDeleteClose} color="primary">
							No, keep it.
						</Button>
						<Button onClick={handleDeleteYes} color="primary">
							Yes, delete.
						</Button>
					</DialogActions>
				</Dialog>
			</Modal>
			<Box style={{ height: "auto", width: "100%", padding: 0 }}>
				<TableToExcel
					id="tte_apsummary"
					sheet="Worksheet"
					table="apsummary"
					fileName="apsummary"
					buttonText=""
					className=""
				/>
			</Box>
			<TableContainer style={{ width: "100%" }}>
				<Table id="apsummary" stickyHeader={true} size={"small"}>
					<SortableTableHeaders
						classes={classes}
						numSelected={1}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={0}
						headCells={HeaderDataApSums}
					/>
					<TableBody>
						{props.apSummaries &&
							stableSort(props.apSummaries, getComparator(order, orderBy)).map((row, index) => {
								return (
									<TableRow
										key={index}
										hover
										onDoubleClick={(event) => handleClick(event, row.id_)}
										tabIndex={-1}
										selected={selectedItem === index}>
										<TableCell>{formatDateNoTimezoneMMDDYYYY(row.pay_to_month)}</TableCell>
										<TableCell>
											{formatCurrency(Number.parseFloat(row.sum_of_approval_amt))}
										</TableCell>
										<TableCell>
											{formatCurrency(Number.parseFloat(row.sum_of_rebill_amt))}
										</TableCell>
										<TableCell>
											<IconButton
												aria-label="delete"
												size="small"
												onClick={() => {
													handleDeleteClick(row.id_)
												}}>
												<DeleteIcon fontSize="inherit" />
											</IconButton>
										</TableCell>
									</TableRow>
								)
							})}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	)
}
